import React, { Dispatch, useContext, useEffect, useReducer } from 'react'
import { Device, Call as TwilioCall, TwilioError } from '@twilio/voice-sdk'
import * as Sentry from '@sentry/react'
import backend from '../backend'
import VoipBar from '../AgentPortal/Voip/VoipBar'
import IdleTimer from '../AgentPortal/Voip/IdleTimer'
import { LeadFeed } from '../types/lead_feed'
import { useShouldVoipBeEnabled } from './queries/shouldVoipBeEnabled'
import { useNotification } from './notification'
import { useTenantConfig } from './TenantConfig'
import { UserLead } from '../types/user_lead'
import { useAuth } from '../components/AuthProvider/auth_provider'

interface VoipContext {
  devMode: boolean
  device?: Device
  campaign?: LeadFeed
  userLead?: UserLead
  inboundCall?: TwilioCall
  inboundCallAccepted: boolean
  outboundCall?: TwilioCall
}

const context = React.createContext<VoipContext>({
  devMode: false,
  inboundCallAccepted: false,
})

export const useVoip = () => useContext(context)

interface VoipActionContext {
  registerCallCampaign: (campaignId: string) => void
  unregisterCallCampaign: () => void
  makeOutboundCall: ({
    userLead,
    agentTwilioNumber,
    userLeadNumber,
  }: {
    userLead: UserLead
    agentTwilioNumber: string
    userLeadNumber: string
  }) => void
  setDevMode: (devMode: boolean) => void
  triggerDevModeInboundCall: () => void
  clearInboundCall: () => void
  clearOutboundCall: () => void
}

const actionsContext = React.createContext<VoipActionContext>({
  registerCallCampaign: () => {},
  unregisterCallCampaign: () => {},
  makeOutboundCall: () => {},
  setDevMode: () => {},
  triggerDevModeInboundCall: () => {},
  clearInboundCall: () => {},
  clearOutboundCall: () => {},
})
export const useVoipActions = () => useContext(actionsContext)

interface VoipState {
  devMode: boolean
  device?: Device
  campaignId?: string
  inboundCall?: TwilioCall
  inboundCallAccepted: boolean
  outboundCall?: TwilioCall
  context?: {
    campaign?: LeadFeed
    userLead?: UserLead
  }
  shouldRefreshToken: boolean
  error?: string
}

let defaultDevMode = process.env.NODE_ENV === 'development'
if (process.env.REACT_APP_DEFAULT_DEV_MODE === 'false') defaultDevMode = false
const sessionDevMode = sessionStorage.getItem('voipDevMode')
if (sessionDevMode === 'true') defaultDevMode = true
if (sessionDevMode === 'false') defaultDevMode = false

const defaultVoipState: VoipState = {
  devMode: defaultDevMode,
  inboundCallAccepted: false,
  shouldRefreshToken: false,
}

export enum VoipActionType {
  SetDevMode = 'set_dev_mode',
  SetDevice = 'set_device',
  SetCampaignId = 'set_campaign_id',
  SetShouldRefreshToken = 'set_should_refresh_token',
  SetAudioDevices = 'set_audio_devices',
  SetContext = 'set_context',
  ClearContext = 'clear_context',
  SetError = 'set_error',
  SetInboundCall = 'set_inbound_call',
  SetOutboundCall = 'set_outbound_call',
  EndCall = 'end_call',
}

type VoipAction =
  | { type: VoipActionType.SetDevMode; devMode: VoipState['devMode'] }
  | { type: VoipActionType.SetDevice; device: VoipState['device'] }
  | { type: VoipActionType.SetCampaignId; campaignId?: VoipState['campaignId'] }
  | {
      type: VoipActionType.SetShouldRefreshToken
      shouldRefreshToken: VoipState['shouldRefreshToken']
    }
  | { type: VoipActionType.SetAudioDevices }
  | { type: VoipActionType.SetContext; context: VoipState['context'] }
  | { type: VoipActionType.ClearContext }
  | { type: VoipActionType.SetError; error?: string }
  | {
      type: VoipActionType.SetInboundCall
      inboundCall?: VoipState['inboundCall']
      inboundCallAccepted?: boolean
    }
  | {
      type: VoipActionType.SetOutboundCall
      outboundCall?: VoipState['outboundCall']
    }
  | {
      type: VoipActionType.EndCall
      outboundCall?: undefined
      inboundCall?: undefined
    }

const reducer = (state: VoipState, action: VoipAction): VoipState => {
  switch (action.type) {
    case VoipActionType.SetDevMode:
      sessionStorage.setItem('voip-dev-mode', action.devMode ? 'true' : 'false')
      return {
        ...state,
        devMode: action.devMode,
      }
    case VoipActionType.SetDevice:
      return {
        ...state,
        device: action.device,
      }
    case VoipActionType.SetCampaignId:
      return {
        ...state,
        campaignId: action.campaignId,
      }
    case VoipActionType.SetShouldRefreshToken:
      return {
        ...state,
        shouldRefreshToken: action.shouldRefreshToken,
      }
    case VoipActionType.SetContext:
      return {
        ...state,
        context: {
          ...state.context,
          ...action.context,
        },
      }
    case VoipActionType.ClearContext:
      return {
        ...state,
        context: undefined,
      }
    case VoipActionType.SetError:
      return {
        ...state,
        error: action.error,
      }
    case VoipActionType.SetInboundCall:
      return {
        ...state,
        inboundCall: action.inboundCall,
        inboundCallAccepted: action.inboundCallAccepted ?? state.inboundCallAccepted,
      }
    case VoipActionType.SetOutboundCall:
      return {
        ...state,
        outboundCall: action.outboundCall,
      }
    case VoipActionType.EndCall:
      return {
        ...state,
        inboundCall: undefined,
        outboundCall: undefined,
      }
    default:
      return state
  }
}

class DevModeInboundCall {
  state: TwilioCall.State
  muted: boolean = false
  parameters: Record<string, string>
  dispatch: Dispatch<VoipAction>

  constructor(_dispatch: Dispatch<VoipAction>) {
    this.state = TwilioCall.State.Pending
    this.parameters = {
      From: '+18598675309',
    }
    this.dispatch = _dispatch
  }

  status() {
    return this.state
  }

  accept() {
    this.state = TwilioCall.State.Open
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
      inboundCallAccepted: true,
    })
  }

  reject() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
      inboundCallAccepted: false,
    })
  }

  disconnect() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
    })
  }

  mute(shouldMute?: boolean) {
    this.muted = shouldMute ?? true
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
    })
  }

  isMuted() {
    return this.muted
  }

  sendDigits(key: string) {
    console.log(`sent key ${key}`)
  }
}

class DevModeOutboundCall {
  state: TwilioCall.State
  muted: boolean = false
  customParameters: Map<string, string>
  dispatch: Dispatch<VoipAction>

  constructor(_dispatch: Dispatch<VoipAction>) {
    this.state = TwilioCall.State.Open
    // Outbound parameters are in a map called customParameters
    this.customParameters = new Map()
    this.customParameters.set('From', '+18598675309')
    this.customParameters.set('To', '+18596636512') // In prod, this is where we'll grab the userLead's number from
    this.dispatch = _dispatch
  }

  status() {
    return this.state
  }

  accept() {
    this.state = TwilioCall.State.Open
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  reject() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  disconnect() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  mute(shouldMute?: boolean) {
    this.muted = shouldMute ?? true
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  isMuted() {
    return this.muted
  }

  setError() {
    this.state = TwilioCall.State.Closed
    this.dispatch({ type: VoipActionType.SetError, error: 'There was an error' })
    this.dispatch({ type: VoipActionType.ClearContext })
  }

  sendDigits(key: string) {
    console.log(`sent key ${key}`)
  }
}

const getToken = async (): Promise<string> => {
  const { body } = await backend.get('/voip/token')
  return body.token
}

const initDevice = async ({
  dispatch,
  supportEmail,
}: {
  dispatch: React.Dispatch<VoipAction>
  supportEmail: string
}) => {
  const token = await getToken()
  const device = new Device(token, {
    logLevel: 1, //process.env.NODE_ENV === 'production' ? 'silent' : 'info',
  })

  // Register on page load so users can start receiving inbound calls automatically
  device.register()

  device.on('registered', () => dispatch({ type: VoipActionType.SetDevice, device }))
  device.on('unregistered', () => {
    dispatch({ type: VoipActionType.SetError })
  })
  device.on('incoming', handleIncomingCall(dispatch))
  device.on('tokenWillExpire', () =>
    dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: true })
  )
  device.on('error', (error: TwilioError.TwilioError) => {
    dispatch({ type: VoipActionType.SetDevice, device })
    const message =
      error.name === 'NotSupportedError'
        ? 'You are using an unsupported browser. The latest versions of Chrome, Safari, Firefox, and Edge are supported.'
        : error.name === 'PermissionDeniedError'
        ? 'Please grant access to your microphone.'
        : error.name === 'ConnectionError'
        ? 'The connection that allows calls to be taken has been lost. Please check your internet connection.'
        : `Sorry, something went wrong. Please contact our support team at ${supportEmail} for help troubleshooting your issue.`

    dispatch({ type: VoipActionType.SetError, error: message })

    Sentry.captureException(error)
  })

  dispatch({ type: VoipActionType.SetDevice, device: device })
}

const fetchCampaignContext = async (campaignId: string, dispatch: React.Dispatch<VoipAction>) => {
  const response = await backend.get(`/lead-feeds/${campaignId}`)
  dispatch({ type: VoipActionType.SetContext, context: { campaign: response.body } })
}

const handleIncomingCall = (dispatch: React.Dispatch<VoipAction>) => (call: TwilioCall) => {
  const currentTitle = document.title
  document.title = '📞 INCOMING CALL'

  try {
    Sentry.captureMessage('Incoming call', { level: 'info' })
    Sentry.setContext('callContext', {
      key: call.parameters.CallSid,
    })
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
  }

  dispatch({ type: VoipActionType.SetInboundCall, inboundCall: call })
  // Trigger state changes on call events
  // This way we can reliably use call.status() in our components
  call.on('accept', (call: TwilioCall) => {
    dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: call,
      inboundCallAccepted: true,
    })
    document.title = currentTitle
    setTimeout(() => call.sendDigits('1'), 1000)
  })
  call.on('cancel', () => {
    document.title = currentTitle
    dispatch({ type: VoipActionType.SetInboundCall })
  })
  call.on('reject', () => {
    document.title = currentTitle
    dispatch({ type: VoipActionType.SetInboundCall })
  })
  call.on('disconnect', (call: TwilioCall) => {
    document.title = currentTitle
    dispatch({ type: VoipActionType.SetInboundCall, inboundCall: call })
  })
  call.on('mute', (_: void, call: TwilioCall) =>
    dispatch({ type: VoipActionType.SetInboundCall, inboundCall: call })
  )
}

const useCheckForActiveCallCampaign = ([state, dispatch]: VoipStateAndDispatch) => {
  const { data } = useShouldVoipBeEnabled()

  useEffect(() => {
    if (data?.enabled_call_campaign_id && !state.campaignId) {
      dispatch({ type: VoipActionType.SetCampaignId, campaignId: data.enabled_call_campaign_id })
    }
  }, [data, state.device])
}

const useInit = ([state, dispatch]: VoipStateAndDispatch) => {
  const tenantConfig = useTenantConfig()
  const { user } = useAuth()
  useEffect(() => {
    if (
      !state.devMode &&
      !user?.impersonator?.id &&
      (tenantConfig.campaigns.call_campaign_voip || tenantConfig.voice.enabled)
    ) {
      initDevice({ dispatch, supportEmail: tenantConfig.emails.support_email })
    }
  }, [])
}

const useRegisterCallCampaign = ([state, dispatch]: VoipStateAndDispatch) => {
  // Register when campaignId is set if not already registered (shouldn't happen since we register on page load but just in case)
  useEffect(() => {
    if (state.campaignId && state.device?.state === Device.State.Unregistered) {
      state.device.register()
    }
  }, [state.campaignId, state.device])

  // Fetch the campaign when campaignId is set
  useEffect(() => {
    if (state.campaignId) fetchCampaignContext(state.campaignId, dispatch)
  }, [state.campaignId])
}

// Fetch a fresh token when shouldRefreshToken is set to true
const useRefreshToken = ([state, dispatch]: VoipStateAndDispatch) => {
  const { user } = useAuth()
  useEffect(() => {
    if (state.shouldRefreshToken) {
      if (state.device && !user?.impersonator?.id) {
        getToken().then((token) => {
          state.device?.updateToken(token)
          dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: false })
        })
      } else {
        dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: false })
      }
    }
  }, [state.shouldRefreshToken, user])
}

const useDisplayError = ([state, dispatch]: VoipStateAndDispatch) => {
  const showNotification = useNotification()

  // Display an error notification when error is set
  useEffect(() => {
    if (state.error) {
      // @ts-expect-error FIXME
      showNotification({ type: 'error', message: state.error })
      dispatch({ type: VoipActionType.SetError })
    }
  }, [state.error])
}

type VoipStateAndDispatch = [VoipState, Dispatch<VoipAction>]

export const VoipProvider: React.FC = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, defaultVoipState)
  useCheckForActiveCallCampaign(stateAndDispatch)
  useInit(stateAndDispatch)
  useRegisterCallCampaign(stateAndDispatch)
  useRefreshToken(stateAndDispatch)
  useDisplayError(stateAndDispatch)

  const [state, dispatch] = stateAndDispatch

  const value = {
    devMode: state.devMode,
    device: state.device,
    inboundCall: state.inboundCall,
    inboundCallAccepted: state.inboundCallAccepted,
    outboundCall: state.outboundCall,
    ...state.context,
  }

  const registerCallCampaign = (campaignId: string) =>
    dispatch({ type: VoipActionType.SetCampaignId, campaignId })

  const tenantConfig = useTenantConfig()

  const unregisterCallCampaign = () => {
    dispatch({ type: VoipActionType.SetCampaignId })
    dispatch({ type: VoipActionType.ClearContext })
    if (!tenantConfig.voice.enabled) {
      state.device?.unregister()
    }
  }

  const setDevMode = (devMode: boolean) => {
    sessionStorage.setItem('voipDevMode', devMode.toString())
    dispatch({ type: VoipActionType.SetDevMode, devMode })
  }

  const triggerDevModeInboundCall = () => {
    dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: new DevModeInboundCall(dispatch) as unknown as TwilioCall,
    })
  }

  const triggerDevModeOutboundCall = ({ userLead }: { userLead: UserLead }) => {
    dispatch({ type: VoipActionType.SetContext, context: { userLead } })
    dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: new DevModeOutboundCall(dispatch) as unknown as TwilioCall,
    })
  }

  const makeOutboundCall = async ({
    userLead,
    agentTwilioNumber,
    userLeadNumber,
  }: {
    userLead: UserLead
    agentTwilioNumber: string
    userLeadNumber: string
  }) => {
    if (!userLead) {
      console.error('Lead not found') // Might want to change this
      return
    }

    if (state.devMode) {
      return triggerDevModeOutboundCall({ userLead })
    }

    dispatch({ type: VoipActionType.SetContext, context: { userLead } })

    try {
      const device = state.device
      if (!device) throw new Error('Device is not registered') // Might want to change this

      const call = await device?.connect({
        params: {
          To: userLeadNumber,
          From: agentTwilioNumber,
          userLeadId: userLead.id,
        },
      })

      dispatch({ type: VoipActionType.SetOutboundCall, outboundCall: call })

      call.on('accept', () =>
        dispatch({
          type: VoipActionType.SetOutboundCall,
          outboundCall: call,
        })
      )
      call.on('cancel', () => dispatch({ type: VoipActionType.SetOutboundCall }))
      call.on('reject', () => dispatch({ type: VoipActionType.SetOutboundCall }))
      call.on('disconnect', (call: TwilioCall) =>
        dispatch({ type: VoipActionType.SetOutboundCall, outboundCall: call })
      )
      call.on('mute', (_: void, call: TwilioCall) =>
        dispatch({ type: VoipActionType.SetOutboundCall, outboundCall: call })
      )
      call.on('error', () => dispatch({ type: VoipActionType.SetError }))
    } catch (e: any) {
      console.error('Error making outbound call', e)
      dispatch({ type: VoipActionType.SetError, error: e.message })
    }
  }

  const clearInboundCall = () => {
    dispatch({ type: VoipActionType.SetInboundCall })
  }

  const clearOutboundCall = () => {
    dispatch({ type: VoipActionType.SetOutboundCall })
    dispatch({ type: VoipActionType.ClearContext })
  }

  const deviceIsRegistered = value.device?.state === Device.State.Registered || value.devMode
  const showVoipBar =
    deviceIsRegistered &&
    (Boolean(state.inboundCall) ||
      Boolean(value.userLead) ||
      Boolean(value.campaign?.product?.type === 'calls'))

  const actions = {
    registerCallCampaign,
    unregisterCallCampaign,
    makeOutboundCall,
    setDevMode,
    triggerDevModeInboundCall,
    clearInboundCall,
    clearOutboundCall,
  }

  return (
    <context.Provider value={value}>
      <actionsContext.Provider value={actions}>
        {children}
        {showVoipBar && <VoipBar />}
        <IdleTimer />
      </actionsContext.Provider>
    </context.Provider>
  )
}
